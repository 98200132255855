<template>
  <v-menu
    ref="rawTime"
    v-model="timePicker"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value="rawTime"
    transition="scale-transition"
    offset-y
    color="cyan"
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        dense
        filled
        :label="lodash.capitalize(placeholder)"
        hide-details
        solo
        :loading="pageLoading"
        :disabled="disabled || pageLoading"
        flat
        :rules="mandatory ? [() => !!time || placeholder + ' is required'] : []"
        prepend-inner-icon="mdi-clock-time-four-outline"
        v-model.trim="time"
        readonly
        v-bind="attrs"
        v-on="on"
        color="cyan"
        :class="{ 'remove-input-border': removeBorder }"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="timePicker"
      v-model="rawTime"
      full-width
      ampm-in-title
      color="cyan"
      v-on:change="$emit('change', true)"
      v-on:click:minute="updateTime(rawTime)"
      v-on:click:period="updateTime(rawTime)"
    ></v-time-picker>
  </v-menu>
</template>

<script>
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  data: () => {
    return {
      rawTime: null,
      timePicker: false,
      time: null,
    };
  },
  props: {
    defaultTime: {
      type: String,
      default: () => {
        return moment().format("hh:mm A");
      },
    },
    pageLoading: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: () => {
        return "Time";
      },
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    removeBorder: {
      type: Boolean,
      default: false,
    },
    startTimePicker: {
      type: Boolean,
      default: false,
    },
    endTimePicker: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateTime(rawTime) {
      if (this.startTimePicker && rawTime == "23:59") {
        rawTime = "00:00";
      }

      if (this.endTimePicker && rawTime == "00:00") {
        rawTime = "23:59";
      }

      this.$nextTick(() => {
        this.rawTime = rawTime;
        this.$refs.rawTime.save(rawTime);
        this.time = rawTime
          ? moment(rawTime, ["HH:mm"]).format("hh:mm A")
          : null;
        this.$emit("change", true);
      });
    },
  },
  mounted() {
    this.time = this.defaultTime;
    this.rawTime = this.time
      ? moment(this.time, ["hh:mm A"]).format("HH:mm")
      : null;
  },
  watch: {
    time: {
      deep: true,
      immediate: true,
      handler(param) {
        this.$emit("update:time-picker", param);
        this.$emit("input", param);
      },
    },
  },
};
</script>
