<template>
  <v-layout class="custom-phone-input">
    <v-flex class="country-code">
      <v-select
        dense
        filled
        label="Country"
        solo
        flat
        v-model="countryCode"
        :items="countryList"
        hide-details
        color="cyan"
        :disabled="disabled"
        :loading="loading"
        item-text="name"
        item-value="iso2"
        item-color="cyan"
        return-object
        v-on:change="updateActiveCountry"
      >
        <template v-slot:selection>
          <div
            :class="activeCountry.iso2.toLowerCase()"
            class="vti__flag mr-2"
          />
        </template>
        <template v-slot:item="data">
          <span :class="data.item.iso2.toLowerCase()" class="vti__flag mr-2" />
          <span>{{ data.item.name }} {{ `+${data.item.dialCode}` }}</span>
        </template>
      </v-select>
    </v-flex>
    <v-flex class="width-100">
      <v-text-field
        :id="id"
        v-mask="phoneMask"
        v-model="phone"
        :rules="rules"
        dense
        filled
        :disabled="disabled"
        :loading="loading"
        label="Phone Number"
        solo
        flat
        color="cyan"
        :hide-details="hideDetails"
        v-on:keyup="
          $emit('input', phone);
          $emit('change', phone);
        "
        v-on:change="
          $emit('input', phone);
          $emit('change', phone);
        "
      >
      </v-text-field>
    </v-flex>
  </v-layout>
</template>
<style src="@/assets/sass/sprite.scss" lang="scss"></style>
<script>
import ValidationMixin from "@/core/plugins/validation-mixin";
import allCountries from "@/core/plugins/country-list";
export default {
  mixins: [ValidationMixin],
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: "phone",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(param1, param2) {
      if (param1 != param2) {
        this.setValues();
      }
    },
  },
  data() {
    return {
      phone: null,
      countryCode: {
        areaCodes: null,
        dialCode: "65",
        iso2: "SG",
        name: "Singapore",
        priority: 0,
      },
      activeCountry: {
        areaCodes: null,
        dialCode: "65",
        iso2: "SG",
        name: "Singapore",
        priority: 0,
      },
      phoneMask: "+65-########",
      countryList: allCountries,
    };
  },
  methods: {
    setValues() {
      const value = this.lodash.replace(this.value, "+", "");
      const phoneCode = this.lodash.split(value, "-", 1);
      if (phoneCode && phoneCode[0]) {
        const countryCode = this.lodash.find(this.countryList, {
          dialCode: phoneCode[0],
        });

        if (countryCode) {
          this.countryCode = countryCode;
          this.activeCountry = countryCode;
        }
      }
      this.phone = this.value ? this.value : null;
    },
    updateActiveCountry() {
      const { dialCode } = this.countryCode;
      if (dialCode) {
        if (dialCode == "65") {
          this.phoneMask = `+${dialCode}-########`;
        } else {
          this.phoneMask = `+${dialCode}-##########`;
        }
      }
      this.phone = null;
      this.activeCountry = this.countryCode;
    },
  },
  computed: {
    rules() {
      const { dialCode } = this.countryCode;
      const maxLimit = dialCode == "65" ? 12 : 16;
      const minLimit = dialCode == "65" ? 12 : 16;
      const rule = [
        this.validateRules.minLength(this.phone, "phone number", minLimit),
        this.validateRules.maxLength(this.phone, "phone number", maxLimit),
      ];
      if (this.required) {
        rule.push(this.validateRules.required(this.phone, "phone number"));
      }
      return rule;
    },
  },
  mounted() {
    this.setValues();
  },
};
</script>
